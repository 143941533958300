<template>
  <main>
    <form>
      <h1 class="pb-16">
        Réservation numéro : {{ booking.uid }}
      </h1>

      <div class='mb-15'>
        <h3 class="pb-2" >Date de création de la réservation: </h3>
        <p>{{ creationDate }}</p>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15'>
        <h3 class="pb-2" >Statut de la réservation:</h3>
        <p> {{ bookingStatues[booking.status-1] }} </p>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15' v-if="booking.status === 2 || booking.status === 5 || booking.status === 3">
        <h3 class="pb-2" >Date {{ booking.status === 3 ? " de refus" : " d' acceptation"}} :</h3>
        <p> {{ statusDate }} </p>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15'>
        <h3 class="pb-2" > Locataire :
          <v-btn
            v-if="booking.userUid"
            small
            class="success ml-7"
            @click="editUser(booking.userUid)"
          >

            {{ booking.user }} 
            <v-icon small class="ml-2"> 
              mdi-open-in-new
            </v-icon>

          </v-btn>
        </h3>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15'>
        <h3 class="pb-2" > Propriétaire :
          <v-btn
            v-if="booking.application.property.owner"
            small
            class="success ml-2"
            @click="editUser(booking.application.property.ownerUid)"
          >

            {{ booking.application.property.owner }} 
            <v-icon small class="ml-2"> 
              mdi-open-in-new
            </v-icon>
            
          </v-btn>
        </h3>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15'>
        <h3 class="pb-2" > Candidature :
          <v-btn
            v-if="booking.application.id"
            small
            class="success ml-2"
            @click="editApplication(booking.applicationUid)"
          >

            {{ booking.application.id }} 
            <v-icon small class="ml-2"> 
              mdi-open-in-new
            </v-icon>
            
          </v-btn>
        </h3>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15'>
        <h3 class="pb-2" >URL du logement:</h3>
        <a target="blank" :href="propertyUrl">
          {{ propertyUrl }}
        </a>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15'>
        <h3 class="pb-2" >Montant du loyer CC:</h3>
        <p> {{ booking.propertyCharges + booking.propertyRent }} Euros </p>
        <v-divider ></v-divider>
      </div>

      <div class='mb-15'>
        <h3 class="pb-2" >Montant frais de reservation:</h3>
        <p> {{ booking.application.property.serviceCharge }} Euros </p>
        <v-divider ></v-divider>
      </div>


    </form>   
    


  </main>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    BaseUrl: process.env.VUE_APP_BASEURL,
    booking: {},
    bookingStatues: [],
    newStatus:Number,
    comment:"",
    creationDate : "",
    statusDate : "",
  }),

  computed:{
    propertyUrl(){
      return `${this.BaseUrl}/location/${this.booking.application.property.locality}-${this.booking.application.property.postalCode}/${this.booking.application.property.propertyTypeLabel}/${this.booking.application.property.rooms}-pieces/${this.booking.application.property.uid}/`
    }
  },

  mounted() {
    axios
      .get(
        process.env.VUE_APP_APIURL +
          "/bookings/" +
          this.$route.params.bookingUid,
        {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
         params : {
							expand : "application"
					}
        }
      )
      .then((res) => {
        this.booking = res.data;
        const status = [
           "1-Demandé 🕒", 
           "2-Payé ✅", 
           "3-Refusé ❌",
           "4-Annulé",
           "5-Accepté",
        ]
        this.bookingStatues = status;
        this.creationDate = new Date(this.booking.creationDate).toLocaleString();
        this.statusDate = new Date(this.booking.statusDate).toLocaleString(); 
      });

  },

  methods: {

   editUser(uid) {
      open(
        this.$router.resolve({ name: "user", params: { userUid: uid } }).href,
        "_blank"
      );
    },

    editApplication(uid) {
      open(
        this.$router.resolve({
          name: "application",
          params: { applicationUid: uid },
        }).href,
        "_blank"
      );
    },
    

  },
};
</script>

<style lang="scss">
main {
  h1{
    text-align: center;
  }
  margin: 10rem 30% ;
}
</style>
